/* You can add global styles to this file, and also import other style files */
html {
    font-family: Roboto, Arial, "Helvetica Neue", sans-serif;
    scrollbar-color: #565656 #393939;
    scrollbar-width: thin;
    color:white;
    background-color: #1e1e1e;
    font-size: 12px;
}
html, body {
    margin:0;
    padding:0;
    height: 100%;
    overflow: hidden;
    /* Following lines could prevent elastic scrolling in mobile Safari,
     * but deteriorates performances a lot:  * /
    width:100%;
    position: fixed;
    */
}

::-webkit-scrollbar {
    width: 6px;
}
::-webkit-scrollbar-track {
    background-color: #393939;
}
::-webkit-scrollbar-thumb {
    background-color: #565656;
    border-radius: 3px;
    border: 0px solid #393939;
}
::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}
::-webkit-scrollbar-corner {
    background-color:transparent;
}


/* app */
input.app-input,
textarea.app-input {
    color:white;
    background-color: rgba(255,255,255,0.145);
    border-style: solid;
    border-width: 0 0 1px 0;
    border-color: #c8c8c8;
    border-radius: 2px 2px 0 0;
    transition: border-width 100ms, padding-bottom 100ms;
}
textarea.app-input,
input.app-input {
    margin: 0;
    padding: 0 4px 1px 4px;
}
input.app-input {
    height: 20px;
}
input.app-input:focus,
textarea.app-input:focus,
input.app-input:hover,
textarea.app-input:hover {
    border-width: 0 0 2px 0;
    padding-bottom: 0;
}
input.app-input:hover,
textarea.app-input:hover {
    border-color: white;
}
input.app-input:focus,
textarea.app-input:focus {
    outline: none;
    border-color: #2196f3;
}

/* app */
button.mat-flat-button {
    padding:0 8px !important;
    height:32px !important;
    line-height: 32px;
    min-width: auto !important;
    .mat-icon {
        margin:0;
        width: 20px;
        height: 20px;
        font-size: 20px;
    }
}
button.mat-mini-fab .mat-button-wrapper {
    line-height: 20px;
}


/* app */
.row {
    --mdc-checkbox-state-layer-size:20px;
    .mat-mdc-checkbox {
        margin:0;
    }
    .mat-mdc-checkbox-touch-target {
        width: 20px;
        height: 20px;
    }

    display: flex;
    align-items: center;
    padding-left:2px;
    padding-right:2px;
    >* {
        margin-top: 1px;
        margin-bottom: 1px;
    }
    >button.mat-flat-button {
        margin:2px !important;
    }
    .small-checkbox label.mat-checkbox-layout {
        display:flex !important;
    }
}


/* app::deep */
button.mat-mdc-icon-button.more-button {
    --mdc-icon-button-state-layer-size:20px;
    width:20px;
    height:20px;
    margin:0;
    padding:0;
    line-height: 20px !important;
    mat-icon {
        width: 12px;
        height: 12px;
        line-height: 12px !important;
        display: block;
        margin: auto;
    }
    svg {
        width: 12px;
        height: 12px;
        display: block;
    }
    .mat-mdc-button-touch-target {
        width:20px;
        height:20px;
    }
}

/* app */
.input-block {
    margin:2px;
    position: relative;

    >label {
        position: absolute;
        color:#c8c8c8;
        font-size:8px;
        padding:4px 4px 4px 4px;
        top:0;
        left:0;
        right:15px;
    }
    >input, >textarea {
        height:32px;
        width:100%;
    }
    >input {
        padding-top:12px;
    }
    >textarea {
        padding-top:17px;
        resize: vertical; 
    }
    >label.opaque {
        background-color:rgba(69,69,69,0.9);
        border-radius: 2px;
    }
    &.disabled {
        opacity: 0.5;
    }
    &.disabled>input {
        border-color: transparent;
    }
    &:focus-within>label {
        color: #2196f3;
    }
}


/* global */
.app-tooltip {
    margin-top:-8px;
}

/* ::deep */
.dialog .mat-mdc-dialog-container {
    --mdc-dialog-container-color:  #252526 !important;
}
.mat-mdc-menu-panel {
    max-width: 320px  !important;
}
.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
    opacity: 0.08;
}